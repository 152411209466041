.PublishingOptions {
  width: 100%;
  padding-left: 16px;
  text-align: right;
  white-space: nowrap;
  button, .Loading {
    margin-right: 16px !important;
  }
}
.PublishingOptionsHelp {
  h3 {
    svg.i2goi {
      position: relative !important;
      top: 12px;
    }
  }
}