.ThemeRadioFilter {
  position: relative;
  min-width: 200px;
  height: 42px;
  margin: 0 4px;
  padding: 0 8px;

  .radioSwitch {
    position: absolute;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--secondaryColorLight);
    border-radius: 24px;
    height: 42px;
    overflow: hidden;
    cursor: pointer;

    .value {
      height: 42px;
      line-height: 42px;
      vertical-align: middle;
      text-align: center;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: bold;

      .i2goi {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 8px;
        left: 10px;
        fill: var(--secondaryColorDarker);
      }
    }
    &.open {
      height: auto;
      border-radius: 20px;
      padding-bottom: 16px;
    }
  }

  .ThemeFilterGroup {
    width: 100%;
    box-sizing: border-box;
    background-color: var(--secondaryColorLight);
    padding: 0;

    .option {
      input {
        margin: 0;
        margin-right: 8px;
      }
      label {
        display: block;
        padding: 8px 16px;
        cursor: pointer;
        text-align: left;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
}