@import "../../../../themes/breakpoints.scss";

.SettingsForm {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media #{$sm} {
    margin-top: var(--mainToolbarHeight);
  }
}