$inputTextAreaBg: white;
$inputTextAreaContainerPadding: 16px;
$inputTextAreaGlobalPadding: 16px;
$inputTextAreaFontSize: 18px;
$inputTextAreaLabelFontSize: 14px;
// $inputTextAreaHeight: $inputTextAreaFontSize + $inputTextAreaLabelFontSize - $inputTextAreaGlobalPadding + 8;
$inputTextAreaHeight: $inputTextAreaFontSize + $inputTextAreaLabelFontSize;
$inputTextAreaTransitionSpeed: .15s;
$inputTextAreaDisabledBg: var(--inputDisabledBg);
$inputTextAreaBorderColor: var(--inputBorderColor);
$inputTextAreaPlaceholderColor: var(--inputPlaceholderColor);

@mixin inputTextAreaMixin() {
  position: relative;
  border: 2px solid $inputTextAreaBorderColor;
  border-radius: 10px;
  background-color: $inputTextAreaBg;
  padding: $inputTextAreaGlobalPadding;
  // padding-top: $inputTextAreaLabelFontSize + 4;
  display: flex;

  &.disabled {
    background-color: $inputTextAreaDisabledBg;
  }

  textarea {
    border: none;
    outline: none;
    font-size: $inputTextAreaFontSize;
    font-family: var(--primaryFontFamily);
    box-sizing: border-box;
    display: block;
    padding: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: $inputTextAreaFontSize * 3;

    &:focus ~ label, &.notEmpty ~ label, &:disabled ~ label {
      top: -($inputTextAreaGlobalPadding+3px);
      font-size: $inputTextAreaLabelFontSize;
      color: var(--inputLabelFocusColor);
    }

    &:disabled {
      background-color: $inputTextAreaDisabledBg;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
    &:focus {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: $inputTextAreaPlaceholderColor;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $inputTextAreaPlaceholderColor;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $inputTextAreaPlaceholderColor;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: $inputTextAreaPlaceholderColor;
      }
    }
  }

  label {
    display: block;
    position: absolute;
    transition: top $inputTextAreaTransitionSpeed, font-size $inputTextAreaTransitionSpeed;
    font-size: $inputTextAreaFontSize;
    top: calc( ($inputTextAreaHeight + $inputTextAreaGlobalPadding - $inputTextAreaFontSize) / 2);

    padding: 2px;
    line-height: 100%;
    color: var(--inputLabelColor);
  }

  &.type-date, &.type-time {
    label {
      top: -($inputTextAreaGlobalPadding+3px);
      font-size: $inputTextAreaLabelFontSize;
    }
  }
}

.InputTextArea {
  padding: $inputTextAreaContainerPadding 0;
  .inputContainer {
    @include inputTextAreaMixin();
  }
}