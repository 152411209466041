.InvitationServiceSelector {
  position: absolute;
  top: calc(var(--mainToolbarHeight) + 32px);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  max-width: 600px;
  display: flex;
  flex-direction: column;

  .option {
    box-sizing: border-box;
    display: flex;
    height: 45%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 4px 24px;
    padding: 8px;
    color: black;

    &:nth-child(1) {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      border: 4px solid var(--primaryColor);
      background-color: transparent;
    }
    &:nth-child(2) {
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      border: 4px solid var(--primaryColor);
      background-color: transparent;
    }
    &:hover, &:active {
      background-color: var(--secondaryColorLight);
      color: var(--onSecondaryColorLight);
      cursor: pointer;
    }

    h2 {
      color: inherit;
      font-size: 36px;
      display: block;
      margin: 0;
    }
    p {
      font-size: 22px;
    }
  }
}
