.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.positionAbsolute {
  position: absolute;
}
.positionRelative {
  position: relative;
}

/**
 * Styles below here tap into specific components styles to make them arange
 * as intended.
 */
.pageFullHeight {
  height: 100vh;
}
.pageWithMenuLayout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "toolBar"
    "page"
    "buttonsBar";

  @media #{$md} {
    grid-template-columns: 180px auto;
    grid-template-areas:
      "toolBar toolBar"
      "mainMenu page"
      "mainMenu buttonsBar";
  }
  /*
  @media #{$lg} {
    grid-template-columns: 2fr 10fr;
    grid-template-areas:
      "toolBar toolBar"
      "mainMenu page"
      "mainMenu buttonsBar";
  }
  */

  .headroom-wrapper {
    grid-area: toolBar;
  }
  .MainMenu.fixed {
    grid-area: mainMenu;
    display: none;
    @media #{$md} {
      display: block;
    }
  }
  .page, .pageFullHeight {
    grid-area: page;
  }
}

.page {
  padding: 8px;
  padding-bottom: 64px;
}
