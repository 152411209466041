@import '../../../../themes/breakpoints.scss';

.ToolbarTitle {
  cursor: default;
  width: auto !important;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  vertical-align: middle;
  flex: 1;
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  h1 {
    color: var(--toolbarTitleColor);
    margin: 0 !important;
    padding: 0 !important;
    text-align: left;
    font-size: 20px;
    line-height: inherit;
    font-weight: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 350px;
    @media #{$xxs} {
      max-width: 150px;
    }
    @media #{$bxs} {
      max-width: 220px;
    }
    @media #{$sm} {
      max-width: 440px;
    }
    @media #{$md} {
      max-width: 640px;
      font-size: 28px;
      font-weight: bold;
    }
    @media #{$lg} {
      max-width: 850px;
    }
    @media #{$xl} {
      max-width: 1000px;
    }
  }
  h2 {
    color: var(--toolbarSubTitleColor);
    margin: 0 !important;
    padding: 0 !important;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    width: 100%;
  }
}
