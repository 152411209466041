.InvitationGuestsPage {
  .guestsList {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .listSection {
      background-color: white;
      padding: 0;
      margin-top: 24px;
      border-radius: 8px;
      box-shadow: var(--elevation-1);
      h2 {
        margin-bottom: 0;
        padding: 8px;
        border-bottom: 1px solid;
      }
      ul {
        margin: 0;
        padding: 8px;
        list-style: none;
        li {
          padding: 0;
          padding-left: 34px;
          margin: 0;
          margin-bottom: 8px;
          svg {
            margin-left: -34px;
            position:relative;
            top: 10px;
          }
          .selfEmail {
            font-family: monospace;
            font-size: .8em;
            font-weight: 550;
            letter-spacing: .1em;
          }
          .selfCode {
            font-family: monospace;
            font-size: .8em;
            font-weight: 550;
            letter-spacing: .1em;
            color: #aa0000;
          }
        }
      }
    }
  }
}