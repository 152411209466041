@import '../../../../themes/breakpoints.scss';

.ProfileCard {
  position: relative;
  width: 150px;
  min-height: 150px;
  height: auto;
  padding: 4px;
  margin: 8px;
  box-sizing: border-box;

  @media #{$xxs} {
    width: 115px;
    min-height: 115px;
  }

  @media #{$md} {
    width: 200px;
    min-height: 200px;
  }

  .name {
    padding: 0 38px 4px 8px;
    font-weight: bold;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .language {
    font-size: .85em;
    padding: 0 8px 8px 8px;
    text-align: left;
  }

  .buttons {
    position: absolute;
    bottom: 3px;
    right: 8px;
    button {
      width: 38px;
      height: 38px;
      padding: 4px !important;
    }
  }
}