.OnBoard {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;

  height: 100%;
  display: flex;
  // align-items: center;

  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    font-size: 20px;
    color: #ccc;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
      padding: 0;
      max-width: 180px;
      box-sizing: border-box;
    }
  }
}