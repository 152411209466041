.Card {
  position: relative;
  $borderRadius: 8px;
  background-color: white;
  display: inline-block;
  border-radius: $borderRadius;
  box-shadow: var(--elevation-1);
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  &.clickable {
    box-shadow: var(--elevation-3);
    cursor: pointer;
    &.clickable:active {
      box-shadow: var(--elevation-1);
    }
    &:hover {
      box-shadow: var(--elevation-4);
    }
  }
}