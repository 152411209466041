.InvitationPostsPage {
  .content {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      text-align: center;
      padding: 0 8px;
    }
  }
}