.mascot-sprite {
  background: transparent url('img/mascot-sprite.png');
  background-repeat: no-repeat;
  width: 85px;
  height: 100px;
  display: inline-block;

  &.welcome {
    background-position: -170px 0;
  }
}
