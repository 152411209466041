.ProfileAvatar {
  position: relative;
  border: 1px solid black;
  width: 180px;
  height: 180px;
  overflow: hidden;
  .editorButton {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, 0.0);
    padding-right: 8px;
    padding-bottom: 8px;

    button.icon-button {
      width: 42px;
      height: 42px;
      padding: 6px !important;
    }
  }
}