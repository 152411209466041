.Explain {
  font-size: 12px;
  &.medium {
    font-size: 18px;
  }
  &.large {
    font-size: 24px;
  }
  p {
    margin-bottom: 2px;
  }
}