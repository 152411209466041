.InvitationOutboxPage {
  h2 {
    text-align: center;
  }
  .outboxEntryForm {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      margin-bottom: 0;
      padding: 0 8px;
    }
  }
}