@import '../../../../../../themes/breakpoints.scss';

.ThemeFiltersModalControls {
  &.ReactModal__Content {
    z-index: 2000;
    background-color: var(--offWhiteFromSecondary);
    @media #{$md} {
      display: none !important;
    }
  }

  .filtersPanel {
    padding: 8px;
    border-bottom: 1px solid var(--secondaryColor);
  }
  .buttons {
    text-align: right;
  }
  .ThemeFilterGroup {
    .option {
      padding: 4px;
    }
  }
}
