@import '../../themes/breakpoints.scss';

body.sidebarOpen {
  position: fixed; 
  overflow-y: scroll;
  width: 100%;
}

$sidebarWidthTransitionSpeed: .8s;
$maxProfilesAreaWidth: var(--mainToolbarHeight);
.Sidebar {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  max-width: 280px;
  background-color: var(--offWhiteGray);
  transition: width calc($sidebarWidthTransitionSpeed / 4);
  display: flex;

  &.open {
    width: 100%;
    max-width: 280px;
    transition: width calc($sidebarWidthTransitionSpeed / 2);
    @media #{$md} {
      transition: width $sidebarWidthTransitionSpeed;
    }
  }

  .closeButton {
    height: var(--mainToolbarHeight);
    box-sizing: border-box;
    padding: 12px;
    text-align: right;
    button {
      height: calc(var(--mainToolbarHeight) - 24px);
      width: calc(var(--mainToolbarHeight) - 24px);
    }
  }

  .sidebarProfiles {
    max-width: $maxProfilesAreaWidth;
    background-color: white;
  }

  .MainMenu.sidebar {
    width: 100%;
  }

  @media #{$md} {
    .MainMenu.sidebar {
      display: none;
    }

    .sidebarProfiles {
      width: 100%;
      max-width: 100%;
      white-space: nowrap;
    }
  }
}

.sidebarOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 110%;
  bottom: 0;
  right: 0;
  background-color:  rgba(0, 0, 0, .5);
  transition: left calc($sidebarWidthTransitionSpeed / 4);
  &.open {
    left: 0;
    transition: left calc($sidebarWidthTransitionSpeed / 2);
  }
}
