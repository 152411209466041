@import '../../../../themes/breakpoints.scss';

.InvitationForm {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  form .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "eventType eventType"
      "theme theme"
      "title title"
      "date date"
      "startTm endTm"
      "intro intro"
      "location location"
      "notes notes"
      "signature signature"
      "safeword safeword"
    ; // end grid areas

    @media #{$sm} {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "eventType eventType eventType eventType eventType"
        "theme theme theme theme theme"
        "title title title title title"
        "date date date startTm endTm"
        "intro intro intro intro intro"
        "location location location location location"
        "notes notes notes notes notes"
        "signature signature signature signature signature"
        "safeword safeword safeword safeword safeword"
      ; // end grid areas
    }

    .eventType {
      grid-area: eventType;
    }
    .title {
      grid-area: title;
    }
    .date {
      grid-area: date;
    }
    .startTm {
      grid-area: startTm;
    }
    .endTm {
      grid-area: endTm;
    }
    .intro {
      grid-area: intro;
    }
    .location {
      grid-area: location;
    }
    .notes {
      grid-area: notes;
    }
    .signature {
      grid-area: signature;
    }
    .theme {
      grid-area: theme;
    }
    .safeword {
      grid-area: safeword;
      .description {
        font-size: 0.85em;
        color: gray;
      }
    }
  }
}