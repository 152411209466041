@import '../../../../themes/breakpoints.scss';

.WishItemCard {
  $wishItemCardTextColor: gray;
  padding: 8px;

  .Card {
    width: 100%;
    .wishItemImage {
      background-position: center center;
      background-size: cover;
      height: 200px;
    }

    .wishItemInfo {
      padding: 8px;

      .name {
        font-weight: bold;
      }
      .categoryName {
        margin-top: 8px;
        &:before {
          display: inline-block;
          content: "@";
          margin-right: 2px;
          font-weight: bold;
          font-size: 1.1em;
          color: gray;
        }
      }

      .reservedIn {
        margin-top: 8px;
        color: #aa0000;
        font-size: 1.1em;
        .title {
          font-weight: bold;
        }
      }
    }

    .wishItemButtons {
      padding: 8px;
      text-align: right;
    }
  }
}
