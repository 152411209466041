@import '../sprites.scss';
@import '../breakpoints.scss';
@import '../layout.scss';
@import '../react-modal-overrides.scss';

:root {
  --offWhiteBlue: #f0f8ff;
  --offWhiteGray: #fafafa;
  --offWhiteFromSecondary: #ffeed1;

  --primaryFontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --codeFontFamily: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  --primaryColor: rgb(85, 154, 255);
  --primaryColorLight: #99c2ff;
  --primaryColorDark: #1f79ff;
  --primaryColorDarker: #005de5;
  --onPrimaryColor: white;
  --onPrimaryColorLight: black;

  --secondaryColor: #ffba44;
  --secondaryColorLight: #ffd285;
  --secondaryColorDark: #ffa70f;
  --secondaryColorDarker: #d68700;
  --onSecondaryColor: white;
  --onSecondaryColorLight: #472d00;

  --inputDisabledBg: #b3b3b3;
  --inputBorderColor: var(--primaryColor);
  --inputPlaceholderColor: lightgray;
  --inputLabelColor: var(--secondaryColorDarker);
  --inputLabelFocusColor: black;

  --elevation-1: 0 0 4px gray;
  --elevation-2: 0 0 8px gray;
  --elevation-3: 0 0 12px gray;
  --elevation-4: 0 0 18px gray;

  --toolbarTitleColor: var(--primaryColor);
  --toolbarSubTitleColor: var(--primaryColor);
  --mainToolbarHeight: 60px;
  --menuMaxWidth: 180px;
  --borderRadius: 25px;
}

html,
body {
  font-size: 16px;
  background-color: var(--offWhiteBlue);
  box-sizing: border-box;
}

body {
  font-family: var(--primaryFontFamily);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

h2 {
  color: var(--primaryColor);
  font-size: 20px;
  font-weight: bold;
}
h3 {
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: bold;
}

code {
  font-family: var(--codeFontFamily);
}

hr {
  height: 1px;
  border: none;
  border-bottom: 1px solid gray;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  color: var(--primaryColor);
  font-weight: bold;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.addButtonBadge {
  border: 1px solid black;
  border-radius: 4px;
  background-color: red;
  color: white;
  padding: 2px 4px;
  position: absolute;
  top: -8px;
}


.OnBoard {
  blockquote {
    font-style: italic;
  }
}

fieldset {
  border: none;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;

  h2, h3 {
    text-align: center;
  }
}