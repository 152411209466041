$inputSelectBg: white;
$inputSelectContainerPadding: 16px;
$inputSelectGlobalPadding: 16px;
$inputSelectFontSize: 18px;
$inputSelectLabelFontSize: 14px;
// $inputSelectHeight: $inputSelectFontSize + $inputSelectLabelFontSize - $inputSelectGlobalPadding + 8;
$inputSelectTransitionSpeed: .15s;
$inputSelectDisabledBg: var(--inputDisabledBg);
$inputSelectBorderColor: var(--inputBorderColor);


@mixin inputSelectMixin() {
  position: relative;
  border: 2px solid $inputSelectBorderColor;
  border-radius: 10px;
  background-color: $inputSelectBg;
  padding: $inputSelectGlobalPadding;
  // padding-top: $inputSelectLabelFontSize + 4;

  &.disabled {
    background-color: $inputSelectDisabledBg;
  }

  select {
    border: none;
    outline: none;
    font-size: $inputSelectFontSize;
    font-family: var(--primaryFontFamily);
    box-sizing: border-box;
    display: block;
    padding: 0;
    width: 100%;
    background-color: transparent;

    &:disabled {
      background-color: $inputSelectDisabledBg;
    }
  }

  label {
    display: block;
    position: absolute;
    font-size: $inputSelectLabelFontSize;
    top: -($inputSelectGlobalPadding+3px);
  }
}

.InputSelect {
  padding: $inputSelectContainerPadding 0;
  .inputContainer {
    @include inputSelectMixin();
  }
}