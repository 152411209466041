@import '../../themes/breakpoints.scss';

.InvitationCard {
  $invitationCardTextColor: gray;

  display: inline-block;
  border-color: red;
  width: 100%;
  max-width: 600px;
  padding: 8px;
  box-sizing: border-box;

  @media #{$myMax} {
    max-width: 280px;
  }

  .Card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      'invitationTitle invitationTitle'
      'invitationImage invitationSpecs'
      'invitationButtons invitationButtons'; //
    margin: 0;
    padding: 8px;

    &.asListItem {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid lightgray;
    }

    .invitationService {
      padding: 4px 8px;
      border: none;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: .8em;
      text-align: center;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      &.event {
        color: var(--onPrimaryColor);
        background-color: var(--primaryColor);
      }
      &.wishlist {
        color: var(--onSecondaryColor);
        background-color: var(--secondaryColor);
      }
    }

    .invitationTitle {
      grid-area: invitationTitle;
      height: 30px;
      position: relative;
      color: $invitationCardTextColor;
      text-transform: capitalize;
      font-weight: 590;
      padding-bottom: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .invitationImage {
      grid-area: invitationImage;
      width: 100px;
      margin-right: 4px;
      img {
        display: block;
        width: 100%;
        cursor: pointer;

        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-top: none;
      }
      &.event img {
        border-color: var(--primaryColor);
      }
      &.wishlist img {
        border-color: var(--secondaryColor);
      }
    }
    .invitationSpecs {
      grid-area: invitationSpecs;
      text-align: left;
      padding: 0;
      color: $invitationCardTextColor;
      p {
        font-size: 0.85em;
        font-weight: bold;
        padding: 0;
        margin: 0;
        margin-bottom: 4px;
      }

      .buttonPreview {
        text-align: right;
      }

      .invitationUrl {
        display: none;
      }
    }
    .invitationButtons {
      grid-area: invitationButtons;
      display: flex;
      justify-content: space-between;
      padding: 2px;
      padding-top: 8px;
      height: 32px;
    }
  }
}
