.InvitationOutbox {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  .outboxList {
    form {
      .oubtoxEntryBox {
        .entryOptions {
          padding-top: 28px;
        }
      }
    }
    .oubtoxEntryBox {
      // &:nth-child(even) {
      //   background-color: #fafafa;
      // }
      border-bottom: 1px solid var(--primaryColorLight);
      padding: 16px 8px 2px 8px;
      display: flex;

      .entryData {
        flex: 1;
        .entryNum {
          display: inline;
          // font-family: 'Courier New', Courier, monospace;
          font-weight: bold;
          font-size: 0.9em;
          color: var(--secondaryColorDarker);
          padding-right: 8px;
        }
        .entryName {
          font-weight: normal;
        }
        .entryUuid {
          display: none;
        }
      }
      .entryOptions {
        button {
          background-color: transparent;
          margin-left: 16px !important;
        }
      }
    }
  }
}
