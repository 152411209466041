@import '../../themes/breakpoints.scss';

.ThemeCard {
  $themeCardTextColor: gray;
  display: inline-block;
  border-color: red;
  max-width: 280px;
  padding: 8px;
  box-sizing: border-box;

  &.rowLayout {
    width: 100%;
    max-width: none;
    padding: 0;
  }

  .Card {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      'themeService themeService'
      'themeName themeName'
      'themeTypes themeTypes'
      'engineDeprecationNotice engineDeprecationNotice'
      'themeImage themePrice'
      'themeImage buttonPreview'
      'themeImage buttonChange'
      'themeSpecs themeSpecs'; //
    margin: 0;
    padding: 8px;

    &.asListItem {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid lightgray;
    }

    .themeService {
      grid-area: themeService;
      margin-bottom: 8px;
      border: none;
    }
    .themeName {
      grid-area: themeName;
      color: $themeCardTextColor;
      text-transform: capitalize;
      font-weight: 590;
      padding-bottom: 8px;
    }
    .themeTypes {
      grid-area: themeTypes;
      padding-bottom: 8px;
      .typeName {
        display: inline-block;
        padding: 4px;
        border: 1px solid gray;
        margin-right: 4px;
        font-size: 0.75em;
        border-radius: 4px;
      }
    }

    .themeImage {
      grid-area: themeImage;
      width: 100px;
      height: 100px;
      img {
        display: block;
        width: 100%;
      }
    }
    .themeSpecs {
      grid-area: themeSpecs;
      text-align: left;
      padding: 0;
      padding-top: 8px;
      color: $themeCardTextColor;
      p {
        font-size: 0.75em;
        margin: 0;
        margin-bottom: 8px;
      }
    }
    .buttonPreview {
      grid-area: buttonPreview;
      text-align: right;
      padding: 2px;
    }
    .buttonChange {
      grid-area: buttonChange;
      text-align: right;
      padding: 2px;
    }
    .themePrice {
      grid-area: themePrice;
      padding: 4px 8px;
      text-align: right;
    }
  }
}
