.InputImageUrl {
  .InputText {
    z-index: 1;
  }
  .inputImagePreview {
    width: 150px;
    z-index: 2;
    border: 1px solid var(--primaryColor);
    border-radius: 8px;

    position: relative;
    top: -30px;
    margin: 0 auto;
    overflow: hidden;

    img {
      border-radius: 8px;
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}
