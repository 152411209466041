.Loading:not(.inline) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: rgba(0, 0, 0, 1);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}

.Loading {
  &.inline {
    display: inline;
    svg {
      width: auto;
    }
  }

  svg {
    width: 100%;
    max-width: 200px;
  }

  #floripon {
    animation: floriponRotation .5s infinite linear;
  }

  @keyframes floriponRotation {
    from {
      transform-origin: center;
      transform: rotate(0deg);
    }
    to {
      transform-origin: center;
      transform: rotate(360deg);
    }
  }
}