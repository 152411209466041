.Toolbar {
  height: var(--mainToolbarHeight);
  display: flex;
  background-color: var(--offWhiteBlue);
}

.headroom, .headroom-wrapper {
  z-index: 1000;
}

.headroom--pinned.headroom--scrolled, .headroom--unpinned {
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}
.headroom--unfixed {
  box-shadow: none;
  transition: box-shadow 1s;
}
.headerSpacer {
  margin: 0;
  padding: 0;
}