.FormColumn {
  box-sizing: border-box;
  display: inline-block;
  padding: 4px;
  margin-bottom: 8px;
  width: 100%;

  h2, h3 {
    margin: 0;
    text-align: center;
  }

  &.half {
    width: 50%;
  }
  &.fourth {
    width: 25%;
  }
  &.third {
    width: 33.3333%;
  }
  &.third-third {
    width: 66.6666%;
  }
}
