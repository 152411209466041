@import '../../../../themes/breakpoints.scss';

.ThemeFilters {
  padding: 24px 0;

  .smallScreen {
    display: flex;
    justify-content: center;
    @media #{$md} {
      display: none;
    }

    .i2goi {
      fill: var(--secondaryColor);
    }

    .filterPopupToggler {
      position: relative;
      top: 4px;
      width: 31px;
      height: 31px;
      margin-right: 4px;
    }
    .appliedFilter {
      display: inline-block;
      border: 1px solid var(--primaryColor);
      margin: 4px;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }

  .largeScreen {
    justify-content: center;
    align-items: center;
    display: none;
    @media #{$md} {
      display: flex;
    }
  }
}