.ToolbarButton {
  --toolbarButtonSize: 60px;
  --toolbarButtonPadding: 16px;
  --toolbarButtonColor: var(--secondaryColor);
  --onToolbarButtonColor: var(--onSecondaryColor);

  &.customOption:hover {
    background-color: var(--toolbarButtonColor);
  }

  cursor: pointer;
  box-sizing: border-box;
  width: var(--toolbarButtonSize);
  height: var(--toolbarButtonSize);
  padding: var(--toolbarButtonPadding);

  &:hover svg {
    background-color: var(--toolbarButtonColor);
    fill: var(--onToolbarButtonColor);
  }

  svg {
    fill: var(--toolbarButtonColor);
  }
  img, svg {
    width: calc(var(--toolbarButtonSize) - 2 * var(--toolbarButtonPadding));
    height: calc(var(--toolbarButtonSize) - 2 * var(--toolbarButtonPadding));
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
  }

  &.toolbarAvatar {
    --toolbarAvatarSize: 60px;
    --toolbarAvatarPadding: 4px;
  
    overflow: hidden;
    width: var(--toolbarAvatarSize);
    height: var(--toolbarAvatarSize);
    padding: var(--toolbarAvatarPadding);
    img, svg {
      width: calc(var(--toolbarAvatarSize) - 2 * var(--toolbarAvatarPadding));
      height: calc(var(--toolbarAvatarSize) - 2 * var(--toolbarAvatarPadding));
    }
  }
}