$inputTextBg: white;
$inputTextContainerPadding: 16px;
$inputTextGlobalPadding: 16px;
$inputTextFontSize: 18px;
$inputTextLabelFontSize: 14px;
// $inputTextHeight: $inputTextFontSize + $inputTextLabelFontSize - $inputTextGlobalPadding + 8;
$inputTextHeight: $inputTextFontSize + $inputTextLabelFontSize;
$inputTextTransitionSpeed: .15s;
$inputTextDisabledBg: var(--inputDisabledBg);
$inputTextBorderColor: var(--inputBorderColor);
$inputTextPlaceholderColor: var(--inputPlaceholderColor);
$inputTextLabelOffset: 3px;

@mixin inputTextMixin(
  $containerPadding: $inputTextContainerPadding,
  $globalPadding: $inputTextGlobalPadding,
  $textFontSize: $inputTextFontSize,
  $labelFontSize: $inputTextLabelFontSize,
  $inputHeight: $inputTextHeight,
  $labelOffset: $inputTextLabelOffset,
  $transitionSpeed: $inputTextTransitionSpeed
) {
  position: relative;
  border: 2px solid $inputTextBorderColor;
  border-radius: 10px;
  background-color: $inputTextBg;
  padding: $globalPadding;
  // padding-top: $inputTextLabelFontSize + 4;
  display: flex;

  &.disabled {
    background-color: $inputTextDisabledBg;
  }

  input {
    border: none;
    outline: none;
    font-size: $textFontSize;
    font-family: var(--primaryFontFamily);
    box-sizing: border-box;
    display: block;
    padding: 0;
    width: 100%;
    background-color: transparent;
    height: 24px;

    &:focus ~ label, &.notEmpty ~ label, &:disabled ~ label {
      top: -($globalPadding+$labelOffset);
      font-size: $labelFontSize;
      color: var(--inputLabelFocusColor);
    }

    &:disabled {
      background-color: $inputTextDisabledBg;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
    &:focus {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: $inputTextPlaceholderColor;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $inputTextPlaceholderColor;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $inputTextPlaceholderColor;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: $inputTextPlaceholderColor;
      }
    }
  }

  label {
    display: block;
    position: absolute;
    transition: top $transitionSpeed, font-size $transitionSpeed;
    font-size: $textFontSize;
    top: calc(($inputHeight + $globalPadding - $textFontSize) / 2);

    padding: 2px;
    line-height: 100%;
    color: var(--inputLabelColor);
  }

  &.type-date, &.type-time {
    label {
      top: -($globalPadding+3px);
      font-size: $labelFontSize;
    }
  }

  .leadingIcon, .trailingIcon {
    width: 24px;
    height: 24px;
    .i2goi {
      width: 100%;
      height: 100%;
    }
  }
  .trailingIcon {
    cursor: pointer;
    .i2goi {
      fill: $inputTextBorderColor;
    }
  }
}

.InputText {
  padding: $inputTextContainerPadding 0;
  .inputContainer {
    @include inputTextMixin();
  }

  .error {
    border: 1px solid red;
    color: red;
    padding: 4px;
  }
}