@import '../../themes/breakpoints.scss';

.pageWithMenuLayout .ButtonsBar {
  @media #{$md} {
    &.fixed {
      margin-left: var(--menuMaxWidth);
    }

    &:not(.fullWidth) &:not(.static) {
      margin-left: var(--menuMaxWidth);
    }
  }
}

.ButtonsBar {
  $buttonsBarBorderStyle: 2px solid var(--primaryColor);
  $buttonsBarBorderRadius: 28px;

  z-index: 999;
  grid-area: ButtonsBar;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  @media #{$md} {
    &:not(.fixed) {
      position: static;
      margin-top: var(--mainToolbarHeight) !important;
    }
  }

  .deco {
    background: white;
    border-top: $buttonsBarBorderStyle;
    margin: 0 auto;

    @media #{$md} {
      border-left: $buttonsBarBorderStyle;
      border-right: $buttonsBarBorderStyle;
      border-top-left-radius: $buttonsBarBorderRadius;
      border-top-right-radius: $buttonsBarBorderRadius;
      max-width: 600px;

      &:not(.fixed) {
        border-radius: $buttonsBarBorderRadius;
        // position: static;
        background: white;
        border: 2px solid var(--primaryColor);
        // margin-top: var(--mainToolbarHeight) !important;
      }
    }

    .container {
      display: grid;
      grid-template-columns: 4fr 1fr 4fr;
      grid-template-areas: 'left center right';

      padding: 8px;
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      margin-left: auto;

      .left {
        grid-area: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .right {
        grid-area: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .center {
        grid-area: center;
        min-width: 64px;
        text-align: center;
      }
    }
  }
}

.withButtonsBar {
  padding-bottom: 80px;
}
