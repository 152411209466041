$buttonSize: 24px;
$buttonFontSize: 12px;
$buttonTrailingIconMarginLeft: 2px;
$buttonTrailingIconMarginRight: -6px;
$buttonLeadingIconMarginLeft: -6px;
$buttonLeadingIconMarginRight: 2px;

button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $buttonFontSize;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    background: none !important;
    border-color: gray !important;
    background-color: lightgray !important;
    color: gray !important;
    cursor: inherit;
  }

  span {
    height: $buttonSize;
    line-height: $buttonSize;
    white-space: nowrap;
  }
  .icon {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    .i2goi {
      width: $buttonSize;
      height: $buttonSize;
    }
  }
  .leadingIcon {
    display: inline-block;
    margin-left: $buttonLeadingIconMarginLeft;
    margin-right: $buttonLeadingIconMarginRight;
    .i2goi {
      width: $buttonSize;
      height: $buttonSize;
    }
  }
  .trailingIcon {
    display: inline-block;
    margin-left: $buttonTrailingIconMarginLeft;
    margin-right: $buttonTrailingIconMarginRight;
    .i2goi {
      width: $buttonSize;
      height: $buttonSize;
    }
  }
}

$iconButtonSize: 64px;
$iconButtonIconSize: 42px;
$iconButtonBorderRadius: 50% !important;
$iconButtonPadding: 0 2px !important;

button.icon-button {
  width: $iconButtonSize;
  height: $iconButtonSize;
  border-radius: $iconButtonBorderRadius;
  padding: $iconButtonPadding;
  margin: 0 !important;
  .i2goi {
    width: $iconButtonIconSize;
    height: $iconButtonIconSize;
  }
  &:hover,
  &:focus {
    &::before {
      visibility: hidden;
    }
  }
}

/**
 * Button themes
 */
$buttonVerticalPadding: 2px;
$buttonHorizontalPadding: 24px;
$buttonBorderRadius: 25px;
$buttonShadowBorderWidth: 2px;

$buttonHighlightTop: 1px;
$buttonHighlightHeight: 3px;
$buttonHighlightBottom: auto;
$buttonHighlightLeft: 20px;
$buttonHighlightRight: 20px;
$buttonHighlightWidth: auto;

@mixin buttonSolid($textColor, $lightBg, $highlightBg, $darkBg, $darker) {
  outline: none;
  $borderColor: $darker;

  padding: $buttonVerticalPadding $buttonHorizontalPadding;

  border: none;
  border-top: $buttonShadowBorderWidth solid $lightBg;
  border-bottom: $buttonShadowBorderWidth solid $borderColor;
  border-radius: $buttonBorderRadius;

  background: linear-gradient(to bottom, $lightBg, $darkBg);
  // background: $lightBg;
  color: $textColor;
  fill: $textColor;
  -webkit-tap-highlight-color: transparent;
  position: relative;

  .i2goi {
    fill: $textColor;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    &::before {
      position: absolute;
      display: block;
      content: ' ';
      top: $buttonHighlightTop;
      right: $buttonHighlightRight;
      bottom: $buttonHighlightBottom;
      left: $buttonHighlightLeft;
      height: $buttonHighlightHeight;
      width: $buttonHighlightWidth;
      border-radius: $buttonBorderRadius;
      background-color: $highlightBg;
    }
  }

  &:active {
    background: linear-gradient(to bottom, $darkBg, $lightBg);
    border-top: $buttonShadowBorderWidth solid $darker;
    border-bottom: $buttonShadowBorderWidth solid $lightBg;
    &::before {
      visibility: hidden;
    }
  }
}

$buttonOutlineBorderWidth: 2px;
@mixin buttonOutline($color, $dark) {
  outline: none;
  $bg: white;
  $borderColor: $color;
  $borderDarkColor: $dark;
  $textColor: $color;
  $textDarkColor: $dark;

  padding: $buttonVerticalPadding $buttonHorizontalPadding;

  border: $buttonOutlineBorderWidth solid $borderColor;
  border-radius: $buttonBorderRadius;
  &.borderless {
    border: none;
  }

  background: $bg;
  color: $textColor;
  -webkit-tap-highlight-color: transparent;
  position: relative;

  .i2goi {
    fill: $textColor;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    &::before {
      position: absolute;
      display: block;
      content: ' ';
      top: $buttonHighlightTop;
      right: $buttonHighlightRight;
      bottom: $buttonHighlightBottom;
      left: $buttonHighlightLeft;
      height: $buttonHighlightHeight;
      width: $buttonHighlightWidth;
      border-radius: $buttonBorderRadius;
      border: 1px solid $borderDarkColor;
      background-color: $bg;
    }
  }

  &:active {
    color: $textDarkColor;
    border-color: $borderDarkColor;
    .i2goi {
      fill: $textDarkColor;
    }
    &::before {
      visibility: hidden;
    }
  }
}


@mixin buttonText($color, $dark) {
  outline: none;
  $bg: transparent;
  $borderColor: transparent;
  $borderDarkColor: transparent;
  $textColor: $color;
  $textDarkColor: $dark;

  padding: $buttonVerticalPadding $buttonHorizontalPadding;

  border: none;

  background: $bg;
  color: $textColor;
  -webkit-tap-highlight-color: transparent;
  position: relative;

  .i2goi {
    fill: $textColor;
  }

  &:active {
    color: $textDarkColor;
    .i2goi {
      fill: $textDarkColor;
    }
    &::before {
      visibility: hidden;
    }
  }
}


button.solid.primary {
  @include buttonSolid(
    var(--onPrimaryColor),
    var(--primaryColor),
    var(--primaryColorLight),
    var(--primaryColorDark),
    var(--primaryColorDarker)
  );
}

button.solid.secondary {
  @include buttonSolid(
    var(--onSecondaryColor),
    var(--secondaryColor),
    var(--secondaryColorLight),
    var(--secondaryColorDark),
    var(--secondaryColorDarker)
  );
}

button.outline.primary {
  @include buttonOutline(var(--primaryColor), var(--primaryColorDarker));
}

button.outline.secondary {
  @include buttonOutline(var(--secondaryColor), var(--secondaryColorDarker));
}

button.outline.danger {
  @include buttonOutline(#ff0000, darken(#ff0000, 20%));
}
button.icon-button.danger {
  @include buttonOutline(#ff0000, darken(#ff0000, 20%));
}

button.outline.success {
  @include buttonOutline(#009909, darken(#009909, 20%));
}
button.icon-button.success {
  @include buttonOutline(#009909, darken(#009909, 20%));
}


button.text.primary {
  @include buttonText(var(--primaryColor), var(--primaryColorDarker));
}

button.text.secondary {
  @include buttonText(var(--secondaryColor), var(--secondaryColorDarker));
}

button.text.danger {
  @include buttonText(#ff0000, darken(#ff0000, 20%));
}
button.text.success {
  @include buttonText(#009909, darken(#009909, 20%));
}
