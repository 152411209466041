@import '../../../../themes/breakpoints.scss';

.WishCategoryCard {
  $wishCategoryCardTextColor: gray;
  padding: 8px;
  box-sizing: border-box;

  .Card {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;

    .wishCategoryInfo {
      padding: 8px;
      flex: 1;
      display: flex;
      align-items: center;

      .calculatedImage {
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 1px solid var(--primaryColor);
        background-size: cover;
        .countBadge {
          position: absolute;
          display: inline-block;
          right: 0;
          top: 0;
          right: 4px;
          padding: 0px 6px;
          border-radius: 4px;
          background: var(--secondaryColorDark);
          color: white;
          font-weight: bold;
        }
      }
      @media #{$xxs} {
        .calculatedImage {
          display: none;
        }
      }

      .name {
        font-weight: bold;
        vertical-align: middle;
        margin-left: 8px;
      }
    }

    .wishCategoryButtons {
      padding: 8px;
      text-align: right;
    }
  }
}
