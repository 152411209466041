.AnimateThumbUp {
  position: absolute;
  background-color: #5599ff !important;
  border-radius: 50%;
  opacity: 0;

  svg {
    fill: #ffcc00 !important;
  }
  &.animate {
    visibility: visible;
    animation-duration: 1s;
    animation-name: bubbleAndBurstAnimation;
  }
}

@keyframes bubbleAndBurstAnimation {
  0% {
    opacity: 0;
    top: 0;
  }
  50% {
    visibility: visible;
    opacity: 1;
    top: -100px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
}