.titleBar {
  position: relative;
  h2 {
    margin: 8px 0;
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: 32px;
    text-overflow: ellipsis;
  }
  .i2goi {
    position: absolute;
    right: 0px;
    top: -8px;
  }
}
img {
  width: 100%;
}