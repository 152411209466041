@import '../../../../themes/breakpoints.scss';

.ProfilesQuickAccess {
  width: 100%;
  margin-left: auto;
  margin-right: 0;

  .profileQuickAccess {
    width: 100%;
    box-sizing: border-box;
    margin: 1px 0;
    display: flex;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, .05);
    }

    @media #{$md} {
      width: 100%;
    }

    .profileName {
      flex: 1;
      line-height: var(--mainToolbarHeight);
      padding-left: 4px;
      display: none;
      @media #{$md} {
        display: block;
      }
    }
    .profileAvatar {
      width: var(--mainToolbarHeight);
      height: var(--mainToolbarHeight);
      display: inline-block;
    }

    &.activeProfile  {
      margin-bottom: 4px 0;
      @media #{$md} {
        background-color: var(--secondaryColor);
        color: var(--onSecondaryColor);
      }
      .profileName {
        font-weight: bold;
      }
      .profileAvatar {
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        margin: 2px 0;
        .profileName {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: 16px;
          display: block;
          padding: 0;
          padding-top: 8px;
          line-height: 1em;
          font-family: sans-serif;
          font-size: 10px;
          text-transform: uppercase;
          text-align: center;
          // left: 50%;
          // transform: translateX(-50%);
          color: white;
          background-color: rgba(0,0,0,.5);
          @media #{$md} {
            display: none;
          }
        }
      }
    }
  }
}