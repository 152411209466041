$linkButtonPadding: 10px;
$linkButtonFontSize: 12px;
$linkButtonSize: 24px;
$buttonTrailingIconMarginLeft: 2px;
$buttonTrailingIconMarginRight: 2px;
$buttonLeadingIconMarginLeft: 2px;
$buttonLeadingIconMarginRight: 2px;

.LinkButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $linkButtonPadding 0;
  font-size: $linkButtonFontSize;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;

  span {
    height: $linkButtonSize;
    line-height: $linkButtonSize;
    white-space: nowrap;
  }
  .leadingIcon {
    display: inline-block;
    margin-left: $buttonLeadingIconMarginLeft;
    margin-right: $buttonLeadingIconMarginRight;
  }
  .trailingIcon {
    display: inline-block;
    margin-left: $buttonTrailingIconMarginLeft;
    margin-right: $buttonTrailingIconMarginRight;
  }

  .i2goi {
    width: $linkButtonSize;
    height: $linkButtonSize;
  }

  &.primary {
    color: var(--primaryColor);
    .i2goi {
      fill: var(--primaryColor);
    }
  }

  &.secondary {
    color: var(--secondaryColor);
    .i2goi {
      fill: var(--secondaryColor);
    }
  }
}