.MainMenu {
  $mainMenuOptionHeight: 42px;
  $mainMenuOptionPadding: 12px;
  $mainMenuOptionPaddingLeft: 24px;
  $mainMenuOptionBorderRadius: 50px;

  $fixedMainMenuWidth: var(--menuMaxWidth);
  width: $fixedMainMenuWidth;

  &.fixed {
    position: fixed;
    // top: var(--mainToolbarHeight);
    top: 0;
    left: 0;
    bottom: 0px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      width: 100%;

      a {
        box-sizing: border-box;
        border-top-right-radius: $mainMenuOptionBorderRadius;
        border-bottom-right-radius: $mainMenuOptionBorderRadius;
        border: 1px solid transparent;
        border-left: none;
        outline: none;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
        padding: $mainMenuOptionPadding;
        padding-left: $mainMenuOptionPaddingLeft;

        &:focus, &:visited, &:link, &:active, &:hover {
          text-decoration: none;
        }
        &:hover {
          background-color: rgba(0, 0, 0, .05);
        }
        &:active {
          background-color: var(--secondaryColor);
          color: var(--onSecondaryColor) !important;
        }
      }
      &.current a {
        cursor: default;
        background-color: var(--secondaryColorLight);
        color: var(--onSecondaryColor) !important;
      }
    }
  }

  /**
   * To make the menu fixedly positioned and to show
   * a discreet scrollbar use this syling here.
   */
  .logo {
    display: block;
    height: var(--mainToolbarHeight);
    padding: 10px;
    box-sizing: border-box;
    img {
      display: block;
      height: 40px;
    }
  }

  // Scrollbar related
  overflow-y: auto;
  padding-right: 4px;
  &::-webkit-scrollbar {
      width: 6px;
  }
  &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px var(--primaryColorLight);
  }

  &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px var(--primaryColorLight);
  } 
}