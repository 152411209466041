.Card.AvatarCard {
  width: 150px;
  height: 150px;
  margin: 8px;
  cursor: pointer;
  border-radius: 8px;
  img {
    border-radius: 8px;
  }
}
