.InvitationPosts {
  margin-top: 32px;
  margin-bottom: 64px;

  .InvitationPostCard {
    box-sizing: border-box;
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }

    .postEntryBox {
      border-radius: 16px;
      background-color: white;

      .content {
        box-sizing: border-box;
        padding: 8px;
      }
      .tools {
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        padding: 8px;

        .level {
          font-size: .8em;
          padding: 4px 8px;
          border-radius: 12px;
          // border: 2px solid black;
      
          &.warning {
            background-color: #fad289;
            .meta-container .timeago {
              color: black;
            }
          }
      
          &.critical {
            background-color: #aa0000;
            color: white;
            .meta-container .timeago {
              color: white;
            }
          }    
        }
      }
    }
  }
}