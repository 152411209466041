@import "../../../../themes/breakpoints.scss";

.ProfileForm {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{$sm} {
    margin-top: var(--mainToolbarHeight);
  }
  form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
      "avatar"
      "fields"
      "ButtonsBar";

    @media #{$md} {
      grid-template-columns: 1fr;
      grid-template-areas: 
      "avatar"
      "fields"
      "ButtonsBar";
    }

    .avatar {
      grid-area: avatar;
      width: 100%;
      text-align: center;
      margin: 0 auto;

      .ProfileAvatar {
        margin: 0 auto;
      }
    }
    .fields {
      grid-area: fields;
    }
  }
}