.StatusBanner {
  box-sizing: border-box;
  max-width: 600px;
  margin: 8px auto;
  margin-bottom: 16px;
  border-radius: 10px;
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  &.default {
    background-color: rgb(237, 237, 237);
    color: rgb(90, 90, 90);
    border: 4px solid rgb(90, 90, 90);
  }
  &.green {
    background-color: rgb(233, 255, 234);
    color: #00aa00;
    border: 4px solid #00aa00;
  }
  &.red {
    background-color: rgb(255, 233, 233);
    color: #aa0000;
    border: 4px solid #aa0000;
  }
}